import React, { ComponentPropsWithRef, FC } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { PlcColor } from '@styles/colors';

// SVG generated from http://danilowoz.com/create-content-loader/

export const LOADER_FONT_SIZE_RATIO = 0.6;

export const PlcContentLoader: FC<Omit<IContentLoaderProps, 'viewbox'> & { fixedWidth?: boolean; dark?: boolean }> = ({
  backgroundColor,
  foregroundColor,
  speed = 2,
  height = 150,
  width = 400,
  preserveAspectRatio = 'none',
  fixedWidth = false,
  style,
  dark,
  uniqueKey,
  ...rest
}) => {
  return (
    <ContentLoader
      backgroundColor={backgroundColor || (dark ? PlcColor.GREY_CLOUD_MEDIUM : '#f3f3f3')}
      foregroundColor={foregroundColor || (dark ? PlcColor.GREY_CLOUD_DARK : '#ecebeb')}
      height={height}
      preserveAspectRatio={preserveAspectRatio}
      speed={speed}
      style={{ boxSizing: 'content-box', width: fixedWidth ? undefined : '100%', ...style }}
      uniqueKey={`cl-${uniqueKey}`}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      {...rest}
    />
  );
};

export const PlcRect: FC<ComponentPropsWithRef<'rect'>> = ({
  height = 150,
  width = 400,
  rx,
  ry,
  x = 0,
  y = 0,
  ...rest
}) => {
  const defaultRadius = Number(height) / 2;
  return (
    <rect
      height={height}
      rx={rx === undefined ? defaultRadius : rx}
      ry={ry === undefined ? defaultRadius : ry}
      width={width}
      x={x}
      y={y}
      {...rest}
    />
  );
};
