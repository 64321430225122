/* eslint-disable import/no-unresolved */

import {
  allie1,
  allie1Mobile,
  allie1MobileWebp,
  allie1Webp,
  allie2,
  allie2Webp,
  backgroundPlv,
  backgroundPlvWebp,
  connexion,
  connexionBienvenue,
  connexionBienvenueMobile,
  connexionBienvenueMobileWebp,
  connexionBienvenuePro,
  connexionBienvenueProMobile,
  connexionBienvenueProMobileWebp,
  connexionBienvenueProWebp,
  connexionBienvenueWebp,
  connexionMobile,
  connexionMobileWebp,
  connexionWebp,
  creditAgricole,
  creditAgricoleWebp,
  inscription,
  inscriptionMobile,
  inscriptionMobileWebp,
  inscriptionWebp,
  JeuSalonAutomne,
  JeuSalonAutomneMobile,
  JeuSalonAutomneWebp,
  journalMeteo,
  journalMeteoMobile,
  journalMeteoMobileWebp,
  journalMeteoWebp,
  journalPro as journalProImg,
  journalProMobile,
  journalProMobileWebp,
  journalProWebp,
  journalStock,
  journalStockMobile,
  journalStockWebp,
  maskableIcon,
  maskableIconMobile,
  maskableIconMobileWebp,
  maskableIconWebp,
  notification,
  notificationMobile,
  notificationMobileWebp,
  notificationWebp,
  preferences,
  preferencesMobile,
  preferencesMobileWebp,
  preferencesWebp,
  profil,
  profilMobile,
  profilMobileWebp,
  profilWebp,
  villageByCaBlack,
  villageByCaBlackMobile,
  villageByCaBlackMobileWebp,
  villageByCaBlackWebp,
  villageByCaWhite,
  villageByCaWhiteMobile,
  villageByCaWhiteMobileWebp,
  villageByCaWhiteWebp,
} from './imageUrl';

export enum ClippedImageStyle {
  contentAside = 'content-aside',
  journalPro = 'journal-pro',
  journalSlide = 'journal-slide',
  settings = 'settings',
  game = 'game',
  innovationContact = 'innovation-contact',
  innovationBenefice = 'innovation-benefice',
  innovationConceptBloc1 = 'innovation-concept-bloc-1',
  innovationConceptBloc2 = 'innovation-concept-bloc-2',
  innovationConceptBloc3 = 'innovation-concept-bloc-3',
  innovationHighlightcard = 'innovation-highlight-card',
  innovationDetailHeader = 'innovationDetailHeader',
}

export type ImageSrc =
  | 'journal-pro'
  | 'journal-meteo'
  | 'journal-stocks'
  | 'innovation'
  | 'allie-1'
  | 'allie-2'
  | 'inscription'
  | 'connexion'
  | 'bienvenue'
  | 'bienvenue-pro'
  | 'notifications'
  | 'preferences'
  | 'profil'
  | 'plv'
  | 'game'
  | 'ca-logo'
  | 'pwa-banner'
  | 'village-by-ca-black'
  | 'village-by-ca-white';

type LocalImgFragment = { original: string; webp: string };

export function buildLocalImageSrc(imgSrc: ImageSrc): { desktop: LocalImgFragment; mobile?: LocalImgFragment } {
  switch (imgSrc) {
    case 'pwa-banner': {
      return {
        desktop: {
          original: maskableIcon,
          webp: maskableIconWebp,
        },
        mobile: {
          original: maskableIconMobile,
          webp: maskableIconMobileWebp,
        },
      };
    }
    case 'ca-logo': {
      return {
        desktop: {
          original: creditAgricole,
          webp: creditAgricoleWebp,
        },
      };
    }
    case 'journal-pro': {
      return {
        desktop: {
          original: journalProImg,
          webp: journalProWebp,
        },
        mobile: {
          original: journalProMobile,
          webp: journalProMobileWebp,
        },
      };
    }
    case 'game': {
      return {
        desktop: {
          original: JeuSalonAutomne,
          webp: JeuSalonAutomneWebp,
        },
        mobile: {
          original: JeuSalonAutomneMobile,
          webp: JeuSalonAutomneWebp,
        },
      };
    }
    case 'journal-meteo': {
      return {
        desktop: {
          original: journalMeteo,
          webp: journalMeteoWebp,
        },
        mobile: {
          original: journalMeteoMobile,
          webp: journalMeteoMobileWebp,
        },
      };
    }
    case 'journal-stocks': {
      return {
        desktop: {
          original: journalStock,
          webp: journalStockWebp,
        },
        mobile: {
          original: journalStockMobile,
          webp: journalProMobileWebp,
        },
      };
    }
    case 'allie-1': {
      return {
        desktop: {
          original: allie1,
          webp: allie1Webp,
        },
        mobile: {
          original: allie1Mobile,
          webp: allie1MobileWebp,
        },
      };
    }
    case 'allie-2': {
      return {
        desktop: {
          original: allie2,
          webp: allie2Webp,
        },
      };
    }
    case 'inscription': {
      return {
        desktop: {
          original: inscription,
          webp: inscriptionWebp,
        },
        mobile: {
          original: inscriptionMobile,
          webp: inscriptionMobileWebp,
        },
      };
    }
    case 'connexion': {
      return {
        desktop: {
          original: connexion,
          webp: connexionWebp,
        },
        mobile: {
          original: connexionMobile,
          webp: connexionMobileWebp,
        },
      };
    }
    case 'bienvenue': {
      return {
        desktop: {
          original: connexionBienvenue,
          webp: connexionBienvenueWebp,
        },
        mobile: {
          original: connexionBienvenueMobile,
          webp: connexionBienvenueMobileWebp,
        },
      };
    }
    case 'bienvenue-pro': {
      return {
        desktop: {
          original: connexionBienvenuePro,
          webp: connexionBienvenueProWebp,
        },
        mobile: {
          original: connexionBienvenueProMobile,
          webp: connexionBienvenueProMobileWebp,
        },
      };
    }
    case 'notifications': {
      return {
        desktop: {
          original: notification,
          webp: notificationWebp,
        },
        mobile: {
          original: notificationMobile,
          webp: notificationMobileWebp,
        },
      };
    }
    case 'preferences': {
      return {
        desktop: {
          original: preferences,
          webp: preferencesWebp,
        },
        mobile: {
          original: preferencesMobile,
          webp: preferencesMobileWebp,
        },
      };
    }
    case 'profil': {
      return {
        desktop: {
          original: profil,
          webp: profilWebp,
        },
        mobile: {
          original: profilMobile,
          webp: profilMobileWebp,
        },
      };
    }
    case 'plv': {
      return {
        desktop: {
          original: backgroundPlv,
          webp: backgroundPlvWebp,
        },
      };
    }
    case 'village-by-ca-white': {
      return {
        desktop: {
          original: villageByCaWhite,
          webp: villageByCaWhiteWebp,
        },
        mobile: {
          original: villageByCaWhiteMobile,
          webp: villageByCaWhiteMobileWebp,
        },
      };
    }
    case 'village-by-ca-black': {
      return {
        desktop: {
          original: villageByCaBlack,
          webp: villageByCaBlackWebp,
        },
        mobile: {
          original: villageByCaBlackMobile,
          webp: villageByCaBlackMobileWebp,
        },
      };
    }
    default: {
      return imgSrc as never;
    }
  }
}
